define("itarp-admin-dashboard/components/sidebar/list", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class='dashboard-sidebar__links'>
    <li class='dashboard-sidebar__links__item'>
      <LinkTo @route='dashboard' class='dashboard-sidebar__links__link'>
        <span class='dashboard-sidebar__links__link__icon for-home'></span>
        <span class='dashboard-sidebar__links__link__title'>Home</span>
      </LinkTo>
      {{#if @isCollapsed}}
        <EmberPopover
          @tooltipClass='dashboard-sidebar__links__popover'
          @event='hover'
          @side='right'
          @popperContainer='body'
        >
          <LinkTo
            @route='dashboard'
            class='dashboard-sidebar__links__popover__link'
          >
            Home
          </LinkTo>
        </EmberPopover>
      {{/if}}
    </li>
    {{#each @items as |item|}}
      {{#if item.permission}}
        <PermissionContent
          @action={{item.permission.action}}
          @identifier={{item.permission.identifier}}
        >
          <Sidebar::ListItem @item={{item}} @isCollapsed={{@isCollapsed}} />
        </PermissionContent>
      {{else}}
        <Sidebar::ListItem @item={{item}} @isCollapsed={{@isCollapsed}} />
      {{/if}}
    {{/each}}
  </ul>
  */
  {
    "id": "QmbABsWU",
    "block": "[[[10,\"ul\"],[14,0,\"dashboard-sidebar__links\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"dashboard-sidebar__links__item\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"dashboard-sidebar__links__link\"]],[[\"@route\"],[\"dashboard\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"dashboard-sidebar__links__link__icon for-home\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"dashboard-sidebar__links__link__title\"],[12],[1,\"Home\"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[8,[39,2],null,[[\"@tooltipClass\",\"@event\",\"@side\",\"@popperContainer\"],[\"dashboard-sidebar__links__popover\",\"hover\",\"right\",\"body\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,0],[[24,0,\"dashboard-sidebar__links__popover__link\"]],[[\"@route\"],[\"dashboard\"]],[[\"default\"],[[[[1,\"\\n          Home\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[41,[30,3,[\"permission\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@action\",\"@identifier\"],[[30,3,[\"permission\",\"action\"]],[30,3,[\"permission\",\"identifier\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@item\",\"@isCollapsed\"],[[30,3],[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],null,[[\"@item\",\"@isCollapsed\"],[[30,3],[30,1]]],null],[1,\"\\n\"]],[]]]],[3]],null],[13]],[\"@isCollapsed\",\"@items\",\"item\"],false,[\"link-to\",\"if\",\"ember-popover\",\"each\",\"-track-array\",\"permission-content\",\"sidebar/list-item\"]]",
    "moduleName": "itarp-admin-dashboard/components/sidebar/list.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});